import React, { useState, useEffect } from "react";
import useToken from "../../useToken";
import { baseUrl } from "../../config";
import { NavLink, Outlet, useParams } from "react-router-dom";

const Inout = ()=>{
    const [reserva, setReserva] = useState({})
    const {token} = useToken();

    const { id } = useParams();
    
    useEffect(()=>{
        if(token)
            getData();
    },[id, token])

    const getData = async ()=>{
        const result1 = await fetch(baseUrl+"Booking/"+id, {
			headers:{
				'Authorization': `Bearer ${token}`,
			}
		})
		.then(response=>response.json())
		.then(json=>{
			console.log(json);
			return json;
		})
		.catch(error=>{
			console.error(error);
		})
		setReserva(result1?.data || {TypeTime:[]});
    }

    return(
        <>
            <nav className="navReserva">
                <NavLink end={true} to={'/reservaciones/'+reserva?.idBooking}>Información</NavLink>
                <NavLink to={'/reservaciones/'+reserva?.idBooking+'/inout'}>In/Out</NavLink>
            </nav>
            <Outlet context={[reserva, setReserva]}/>
        </>
    )
}

export default Inout;