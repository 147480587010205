import React, { useEffect, useRef, useState } from "react";
import { baseUrl } from '../../config.js'
import { Link, Outlet, useNavigate, useParams } from 'react-router-dom';
import { MapContainer, TileLayer, Marker, useMap, Circle } from 'react-leaflet';
import Calendar from "react-calendar";
import useToken from '../../useToken';

import 'leaflet/dist/leaflet.css';
import 'react-calendar/dist/Calendar.css';
import './Almacen.css';

import Galeria from '../../img/Galeria.png';
import Cargando from '../../img/Cargando.gif';
import Cerrar from '../../img/Cerrar.png';
import Play from '../../img/Play.png';
import { Imagenes, idServiciosRC, tipoAlmacen, idsRC, formatoFecha, formatoFechaTxt, formatoNumero, formatoNumeroEntero, ImagenesTransporte, Dias } from '../../almacen.js';
import descMedidas from '../../img/descMedidas.png';
import Info from '../../img/Info.png';

const Almacen = ({user, setModalLogin})=>{
    const {token} = useToken();
    const [config, setConfig] = useState(localStorage.getItem('config') ? JSON.parse(localStorage.getItem('config')) : {
      idioma : 'es',
      sistema : 'internacional',
    })

    const { id } = useParams();
    const navigate = useNavigate();
    const [publicacion, setPublicacion] = useState({TypeTime:[]});
    const [video, setVideo] = useState({});
    const [info, showInfo] = useState(false);
    const [calendario, setCalendario] = useState(false);
    const [calendarioF, setCalendarioF] = useState(false);
    const [periodo, setPeriodo] = useState("3");
    const hoy = new Date();
    const auxMax = new Date();
    const maxDate = new Date(auxMax.setFullYear(auxMax.getFullYear() + 1));
    const fi = new Date(hoy.getFullYear(), hoy.getMonth(), hoy.getDate()+7);
    const ff = new Date(fi.getTime());
    ff.setDate(fi.getDate()+29);
    const [fechaI, setFechaI] = useState(fi);
    const [fechaF, setFechaF] = useState(ff);
    const [fechaMinima, setFechaMinima] = useState(new Date(hoy.getFullYear(), hoy.getMonth(), hoy.getDate()));
    const [dias, setDias] = useState();
    const [costo, setCosto] = useState();
    const [espacios, setEspacios] = useState(1);
    const [error, setError] = useState("");
    const [reservarFixed, setReservarFixed] = useState(false);
    const [cargando, setCargando] = useState(false);
    const refEspacios = useRef();
    const refDias = useRef();
    const divMedidas = useRef();
    const refCalendario = useRef();
    const refCalendarioF = useRef();

    useEffect(()=>{
        // console.log("cambio fechaF", fechaF)
    }, [fechaF])

    const lblsTiempo = {
        "1" : "Días",
        "2" : "Semanas",
        "3" : "Meses",
        "4" : "Años",
    }

    useEffect(()=>{
        getData();
    },[id, token])

    const handleClickOutside = (event) => {
        if (divMedidas.current && !divMedidas.current.contains(event.target)) {
            showInfo(false);
        }
        else if (refCalendario && refCalendario.current && !refCalendario.current.contains(event.target)) {
            setCalendario(false);
        }
        else if (refCalendarioF && refCalendarioF.current && !refCalendarioF.current.contains(event.target)) {
            setCalendarioF(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);
    
    useEffect(()=>{
        if(fechaF && fechaI){
            let intervalo = Math.ceil((fechaF.getTime() - fechaI.getTime())/86400000)+1;
            const aux = new Date(fechaI.getTime());
            if(periodo == "2"){
                intervalo = Math.round(intervalo*100/7)/100;
            }
            else if(periodo == "3"){
                intervalo = Math.round(intervalo*100/30)/100;
            }
            else if(periodo == "4"){
                intervalo = 0;
                while(fechaF >= aux){
                    intervalo++;
                    aux.setFullYear(aux.getFullYear()+1)
                }
                const diferenciaDias = Math.ceil((fechaF.getTime() - aux)/86400000)+1;
                intervalo += diferenciaDias/(fechaF.getFullYear() % 4 == 0 ? 366 : 365);
            }
            if(intervalo != 0){

                setDias(intervalo)
            
                let total = calculaPrecio();
                total = Math.round(total*100)/100;
                setCosto(total*espacios);
            }
        }
    }, [fechaI, fechaF, publicacion])
    
    useEffect(()=>{
        if(fechaF && fechaI){
            let total = calculaPrecio();
            total = Math.round(total*100)/100;
            setCosto(total*espacios);
        }
    }, [espacios, publicacion])

    useEffect(()=>{
        const data = localStorage.getItem('datosReserva');
        if(dias && !data)
            cambiaFechaF();
        else{
            if(data){
                const aux = JSON.parse(data);
                if(aux?.spaces)
                    setEspacios(aux?.spaces)
                if(aux?.endDate){
                    setFechaF(new Date(aux?.endDate));
                }
                if(aux?.startDate){
                    setFechaI(new Date(aux?.startDate));
                }
                setTimeout(()=>{
                    // localStorage.removeItem('datosReserva');
                }, 1000)
            }
        }
    }, [periodo])

    useEffect(()=>{
        const aux = new Date(fechaI.getTime());
        if(periodo == "2")
            aux.setDate(aux.getDate()+7);
        else if(periodo == "3"){
            aux.setDate(aux.getDate()+29);
        }
        else if(periodo == "4"){
            aux.setFullYear(aux.getFullYear()+1);
            aux.setDate(aux.getDate()-1);
        }
        setFechaMinima(aux);
    }, [periodo, fechaI])
    
    const getData = async ()=>{
        const result1 = await fetch(baseUrl+"Guest/Post/"+id, {
			method : 'get',
			headers: {
				'Accept': 'application/json',
				'Authorization': `Bearer ${token}`,
			},
		})
		.then(response=>response.json())
		.then(json=>{
			console.log(json);
			return json;
		})
		.catch(error=>{
			console.error(error);
		})
        if(!result1?.data?.TypeTime.includes("3"))
            setPeriodo(result1?.data?.TypeTime[0])
        
        setVideo(result1?.data.Photos.find(x=>{
            return x.idCategoryPhoto == 9;
        }) || {})
        setPublicacion(result1?.data || {TypeTime:[]});

    }

    function calculaPrecio(){
        const aux = new Date(fechaI.getTime());
        let tiempo = 0;

        if(publicacion.TypeTime.includes("4") && fechaF.getTime() >= aux.setFullYear(fechaI.getFullYear()+1) - 86400000){
            // el costo será por años
            tiempo++;
            while(fechaF.getTime() > aux.setFullYear(aux.getFullYear()+1) - 86400000){
                tiempo++;
            }
            aux.setFullYear(aux.getFullYear()-1);
            const diferenciaDias = Math.ceil((fechaF.getTime() - aux)/86400000)+1;
            tiempo += diferenciaDias/(fechaF.getFullYear() % 4 == 0 ? 366 : 365);
            return publicacion.year*tiempo;
        }
        else{
            aux.setFullYear(fechaI.getFullYear())
            if(publicacion.TypeTime.includes("3") && fechaF.getTime() >= aux.setDate(fechaI.getDate()+30) - 86400000){
                // el costo será por meses
                tiempo++;
                while(fechaF.getTime() > aux.setDate(aux.getDate()+30)){
                    tiempo++;
                }
                aux.setDate(aux.getDate()-30);
                
                const diferenciaDias = Math.ceil((fechaF.getTime() - aux)/86400000)+1;
                console.log(diferenciaDias)
                tiempo += diferenciaDias/30;
                return publicacion.month*tiempo;
            }
            else{
                aux.setMonth(fechaI.getMonth())
                if(publicacion.TypeTime.includes("2") && fechaF.getTime() >= aux.setDate(fechaI.getDate()+7)){
                    // costo por semana
                    tiempo++;
                    while(fechaF.getTime() > aux.setDate(aux.getDate()+7)){
                        tiempo++;
                    }
                    aux.setDate(aux.getDate()-7);
                    const diferenciaDias = Math.ceil((fechaF.getTime() - aux)/86400000)+1;
                    tiempo += diferenciaDias/7;
                    return publicacion.week*tiempo;
                }
                else{
                    // costo por día
                    tiempo += Math.ceil((fechaF.getTime() - fechaI.getTime())/86400000)+1;
                    return publicacion.day*tiempo;
                }
            }
        }
    }
    const reservar = () =>{
        if(!(refEspacios.current.reportValidity()
            && refDias.current.reportValidity()
        )){
            return false;
        }
        setCargando(true);
        if(espacios <= 0){
            setError("Selecciona la cantidad de espacios");
            setCargando(false);
            return false;
        }
        if(!fechaI || !fechaF){
            setError("Elige un intervalo de fechas correcto");
            setCargando(false);
            return false;
        }
        const data = {
            idPost : id,
            startDate : fechaI || '',
            endDate : fechaF || '',
            spaces : espacios || '',
            price : costo || '',
        }
        fetch(baseUrl+"Booking", {
			method: "post",
			headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/json',
				'Authorization': `Bearer ${token}`,
			},
			body: JSON.stringify(data),
		})
		.then(response=>response.json())
		.then(json=>{
			console.log(json);
            navigate('/reservar/'+json.id);
		})
		.catch(error=>{
            setCargando(false);
			console.error(error);
		})
    }

    const cambiaFechaF = (e)=>{
        const valor = e ? e.target.value*1 : refDias.current.value*1;
        if(valor === 0){
            setDias('');
            setFechaF('');
            return false;
        }
        const aux = new Date(fechaI.getTime());
        if(periodo == "1")
            aux.setDate(aux.getDate()*1 + valor - 1);
        else if(periodo == "2")
            aux.setDate(aux.getDate()*1 + valor*7 - 1);
        else if(periodo == "3"){
            aux.setDate(aux.getDate()*1 + valor*30 - 1);
        }
        else if(periodo == "4"){
            aux.setFullYear(aux.getFullYear()*1 + valor)
            aux.setDate(aux.getDate()-1);
        }
        console.log(aux)
        setFechaF(aux);
    }

    return(
        <div className={"almacen" + (!publicacion?.idPost ? " placeholder" : "")}>
            {publicacion?.status == 0 &&
                <div className="noPublicable">Está publicación se encuentra <b>sin publicar</b></div>
            }
            <h1 className="nombreAlmacen">{publicacion?.name}</h1>
            <div className="imagenesAlmacen">
                <button className="mostrarTodas" onClick={()=>{
                    navigate('fotos');
                }}>
                    <span className="icon">
                        <img src={Galeria}/>
                    </span>
                    <span>Mostrar todas las fotos</span>
                </button>
                {video?.url &&
                    <div onClick={()=>{
                        navigate('fotos/video');
                    }}>
                        <video src={video.url} muted/>
                        <div className="playButton">
                            <img src={Play}/>
                        </div>
                    </div>
                }
                {!video?.url && publicacion?.Photos && publicacion?.Photos[0] &&
                    <div onClick={()=>{
                        navigate('fotos/0');
                    }}>
                        {publicacion?.Photos[0].idCategoryPhoto == 9 &&
                            <video src={publicacion?.Photos[0].url}/>
                        }
                        {publicacion?.Photos[0].idCategoryPhoto != 9 &&
                            <img src={publicacion?.Photos[0].url}/>
                        }
                    </div>
                }
                <div>
                    {
                        publicacion?.Photos?.slice((video?.url ? 0 : 1),5)?.map((imagen, i)=>{
                            return(
                                <div key={i} onClick={()=>{
                                    navigate('fotos/'+(video?.url ? i : i+1));
                                }}>
                                    <img src={imagen.url}/>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            <div className="datosAlmacen">
                <div>
                    {/* <h2 className="descripcionAlmacen">{publicacion?.descripcion || "Descripción del almacén"}</h2> */}
                    <h2 className="ubicacionAlmacen">{publicacion?.Town+", "+ publicacion?.State}</h2>
                    <div className="puntua">
                        <span className="puntuacionAlmacen">{publicacion?.puntuacion || 4.93}</span>
                        <span className="comentariosAlmacen">{publicacion?.comentarios?.length || 15} comentarios</span>
                    </div>
                    <h3>Características del almacén</h3>
                    {publicacion?.inmediate != 0 &&
                        <div className="tiposPost">
                            <div>
                                <span>
                                    <img src={Imagenes["Inmediata"]}/>
                                </span>
                                <span>
                                    <div>Reservación Inmediata</div>
                                    <div>Esta reservación no pasa por un proceso de aprobación por parte del Host</div>
                                </span>
                            </div>
                        </div>
                    }
                    {publicacion?.officeArea &&
                        <div className="tiposPost">
                            <div>
                                <span>
                                    <img src={Imagenes["Oficinas"]}/>
                                </span>
                                <span>
                                    <div>Área de oficinas</div>
                                    <div>El almacén cuenta con <b>{formatoNumero(publicacion?.officeArea)} m<sup>2</sup></b> dedicados a oficinas.</div>
                                </span>
                            </div>
                        </div>
                    }
                    {publicacion?.ramps !== 0 &&
                        <div className="tiposPost">
                            <div>
                                <span>
                                    <img src={Imagenes["Rampa"]}/>
                                </span>
                                <span>
                                    <div>Andenes y Rampas de Carga y Descarga</div>
                                    <div>El almacén cuenta con <b>{publicacion?.platforms} andenes y {publicacion?.ramps} rampas</b>.</div>
                                </span>
                            </div>
                        </div>
                    }
                    <h3>Tipos de Almacenaje</h3>
                    <div className="tiposPost">
                        {publicacion?.typeOfStores?.map((tipo)=>{
                            return(
                                <div key={tipo}>
                                    <span>
                                        <img src={Imagenes[idsRC[tipo]]}/>
                                    </span>
                                    <span>
                                        <div>{tipoAlmacen[tipo].nombre}</div>
                                        <div>{tipoAlmacen[tipo].descripcion}</div>
                                    </span>
                                </div>
                            )
                        })
                        }
                    </div>
                    <div className="seccionDesc">
                        <h3>Servicios que ofrece</h3>
                        <div className="serviciosAlmacen">
                            {publicacion?.operativeServices?.map(servicio=>{
                                return(
                                    <div key={servicio}>
                                        <span>
                                            <img src={Imagenes[idServiciosRC[servicio]]}/>
                                        </span>
                                        <span>{idServiciosRC[servicio]}</span>
                                    </div>
                                )
                            })
                            }
                        </div>
                    </div>
                    {publicacion?.transport && publicacion?.transport?.length !== 0 &&
                        <div className="seccionDesc">
                            <h3>Capacidad de carga/descarga por tipo de vehículo:</h3>
                            <div className="serviciosAlmacen">
                                {publicacion?.transport?.map(transporte=>{
                                    return(
                                        <div key={transporte.idTransport}>
                                            <span>
                                                {ImagenesTransporte[transporte.idTransport] &&
                                                    <img src={ImagenesTransporte[transporte.idTransport]}/>
                                                }
                                            </span>
                                            <span>{transporte.name} ({transporte.capacity})</span>
                                        </div>
                                    )
                                })
                                }
                            </div>
                        </div>
                    }
                    {publicacion?.hours && Object.keys(publicacion.hours).length !== 0 &&
                        <div className="seccionDesc">
                            <h3>Horarios de Atención</h3>
                            <div className="descHorarios">
                                {Object.keys(publicacion.hours).map(x=>{
                                    const dia = publicacion.hours[x];
                                    if(dia.active)
                                    return(
                                        <div key={x} className="diaHorario">
                                            <span>{Dias[x]}</span>
                                            <div>
                                                {dia.hours.map(x=>{
                                                    return(<div>
                                                        {x.start} a {x.end} hrs
                                                    </div>)
                                                })
                                                }
                                            </div>
                                        </div>
                                    )
                                })
                                }
                            </div>
                        </div>
                    }
                    {publicacion?.refund !== 0 && publicacion?.refund !== 100 &&
                        <div className="seccionDesc">
                            <h3>Cancelación Parcial</h3>
                            <div className="descCancela">
                                Si cambias de opinión hasta 7 días antes de la fecha de inicio, recibirás un reembolso parcial.
                            </div>
                        </div>
                    }
                    {publicacion?.refund !== 0 && publicacion?.refund === 100 &&
                        <div className="seccionDesc">
                            <h3>Cancelación gratuita</h3>
                            <div className="descCancela">
                                Si cambias de opinión hasta 7 días antes de la fecha de inicio, recibirás un reembolso total.
                            </div>
                        </div>
                    }
                    {publicacion?.lat &&
                        <>
                            <h2>Ubicación Aproximada</h2>
                            <div className="mapa mapaHuesped">
                                <MapComponent position={{lat: publicacion?.lat, lng: publicacion?.lng}}/>
                            </div>
                        </>
                    }
                </div>
                <div className={"reservar"+(reservarFixed ? " fixed" : "")}>
                    <div>
                        <div className="opcReservar">
                            <button className="cerrar" onClick={()=>{
                                setReservarFixed(false);
                            }}>
                                <img src={Cerrar}/>
                            </button>
                            <button className="btnCancel" onClick={()=>{
                                setFechaI(null);
                                setFechaF(null);
                            }}>
                                Borrar fechas
                            </button>
                        </div>
						<div className="descAlmacen">{formatoNumeroEntero(publicacion.assignedSpaces)} espacio{publicacion.assignedSpaces == 1 ? "" : "s"} disponibles</div>
			            <div className="descAlmacen medidas">
                            <span>Ancho: {config.sistema == 'ingles' ? formatoNumero(publicacion?.width*(3.2808399)/100)+'ft' : formatoNumero(publicacion?.width/100)+'m'}</span>
                            <span>Largo: {config.sistema == 'ingles' ? formatoNumero(publicacion?.length*(3.2808399)/100)+'ft' : formatoNumero(publicacion?.length/100)+'m'}</span>
                            <span>Alto: {config.sistema == 'ingles' ? formatoNumero(publicacion?.height*(3.2808399)/100)+'ft' : formatoNumero(publicacion?.height/100)+'m'}</span>
                            <button className="info" onClick={()=>{
                                showInfo(true)
                            }}>
                                <img src={Info}/>
                            </button>
                            {info &&
                                <div className="descMedidas" ref={divMedidas}>
                                    <button className="cerrar" onClick={()=>{
                                        showInfo(false);
                                    }}>
                                        <img src={Cerrar}/>
                                    </button>
                                    <img src={descMedidas}/>
                                </div>
                            }
                        </div>
                        <div className="descAlmacen medidas">
                            <span>Capacidad de carga: {config.sistema == 'ingles' ? formatoNumero(publicacion?.weight*(2.20462262)/100)+'lb' : formatoNumero(publicacion?.weight/100)+'kg'}</span>
                        </div>
                        <div className="periodo">
                            {publicacion.TypeTime.includes("1") &&
                                <button className={periodo == "1" ? "sel" : ""} onClick={()=>{
                                    setPeriodo("1")
                                }}>
                                    <span>${(publicacion?.day*1 || 0).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')} MXN</span>
                                    <span>Día</span>
                                </button>
                            }
                            {publicacion.TypeTime.includes("2") &&
                                <button className={periodo == "2" ? "sel" : ""} onClick={()=>{
                                    setPeriodo("2")
                                }}>
                                    <span>${(publicacion?.week*1 || 0).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')} MXN</span>
                                    <span>Semana</span>
                                </button>
                            }
                            {publicacion.TypeTime.includes("3") &&
                                <button className={periodo == "3" ? "sel" : ""} onClick={()=>{
                                    setPeriodo("3")
                                }}>
                                    <span>${(publicacion?.month*1 || 0).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')} MXN</span>
                                    <span>Mes*</span>
                                </button>
                            }
                            {publicacion.TypeTime.includes("4") &&
                                <button className={periodo == "4" ? "sel" : ""} onClick={()=>{
                                    setPeriodo("4")
                                }}>
                                    <span>${(publicacion?.year*1 || 0).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')} MXN</span>
                                    <span>Año</span>
                                </button>
                            }
                        </div>
                        {periodo == "3" &&
                            <i className="nota">*Se considera un mes como 30 días naturales.</i>
                        }
                        <form className="resv">
                            <label>
                                <input type="number" ref={refEspacios} min={1} value={espacios} onChange={(e)=>{
                                    setEspacios(e.target.value);
                                }} max={publicacion?.assignedSpaces}/>
                                <span>Cantidad de Espacios</span>
                            </label>
                            <label>
                                <input type="number" ref={refDias} min={1} value={dias ? Math.round(dias*1000)/1000 : ''} onChange={cambiaFechaF} step={'any'}/>
                                <span>{lblsTiempo[periodo] + (periodo == "3" ? '*' : '')}</span>
                            </label>
                            <label onClick={()=>{
                                setCalendario(true);
                                setCalendarioF(false);
                            }}>
                                <input type="text" value={formatoFecha(fechaI)} readOnly={true}/>
                                <span>Desde</span>
                            </label>
                            <label onClick={()=>{
                                setCalendario(false);
                                setCalendarioF(true);
                            }}>
                                <input type="text" value={formatoFecha(fechaF)} readOnly={true}/>
                                <span>Hasta</span>
                            </label>

                            {(calendario || reservarFixed) &&
                                <div ref={refCalendario} className="refCalendario">
                                    <h4>Desde</h4>
                                    <Calendar className="calendario"
                                        defaultActiveStartDate={fechaI} 
                                        tileClassName={({date})=>{
                                            if(date.getTime() == (new Date("2024-05-24".split("-"))).getTime()){
                                                return "highlight";
                                            }
                                        }}
                                        allowPartialRange={fechaF}
                                        value={[fechaI, fechaF]} 
                                        onChange={(value)=>{
                                            setFechaI(value[0]);
                                            if(fechaF > value[0]){
                                                if(!reservarFixed){
                                                    setCalendario(false);
                                                    return false;
                                                }
                                            }
                                            else{
                                                setFechaF(value[0]);
                                            }
                                            if(value[1]){
                                                setFechaF(new Date(value[1].getFullYear(), value[1].getMonth(), value[1].getDate()));
                                                if(!reservarFixed){
                                                    setCalendario(false);
                                                }
                                            }
                                        }}
                                        selectRange={true}
                                        minDate={hoy}
                                        maxDate={maxDate}/>
                                </div>
                            }
                            {calendarioF &&
                                <div ref={refCalendarioF} className="refCalendario">
                                    <h4>Hasta</h4>
                                    <Calendar className="calendario"
                                        defaultActiveStartDate={fechaF || fechaMinima} 
                                        tileClassName={(date)=>{
                                            // console.log(date.date.getTime(), fechaI.getTime())
                                            // return date.date.getTime() == fechaI.getTime() ? "react-calendar__tile--rangeStart" : "";
                                        }}
                                        value={[fechaI, fechaF]} 
                                        tileDisabled={
                                            ({date})=>{
                                                if(date < fechaMinima){
                                                    return true;
                                                }
                                            }   
                                        }
                                        onChange={(value)=>{
                                            setFechaF(value);
                                            setCalendarioF(false);
                                        }}
                                        minDate={hoy}/>
                                </div>
                            }
                        </form>
                        <div className="divReservar">
                            <button className="btn btnReservar" onClick={()=>{
                                const data = {
                                    idPost : id,
                                    startDate : fechaI || '',
                                    endDate : fechaF || '',
                                    spaces : espacios || '',
                                    price : costo || '',
                                }
                                localStorage.setItem('datosReserva', JSON.stringify(data));
                                if(user.sub){
                                    reservar();
                                }
                                else{
                                    setModalLogin(true);
                                }
                            }}>
                                {cargando &&
                                    <img src={Cargando}/>
                                }
                                {!cargando &&
                                    <span>Reservar</span>
                                }
                            </button>
                        </div>
                        {error &&
                            <div className="error">
                                {error}
                            </div>
                        }
                        <div className="sincobro">
                            Aún no se te cobrará nada
                        </div>
                        {fechaI &&
                            <div className="intervalo">
                                <div>
                                    <span>Del</span>
                                    <span>{formatoFechaTxt(fechaI)}</span>
                                </div>
                                {fechaF &&
                                <>
                                    <div>
                                        <span>Hasta</span>
                                        <span>{formatoFechaTxt(fechaF)}</span>
                                    </div>
                                    <div className="total">
                                        <span>Total (MXN)</span>
                                        <span>${(costo || 0).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')} MXN</span>
                                    </div>
                                </>
                                }
                            </div>
                        }
                        <div className="botonesFixed">
                            <div className="total">
                                <span>Total (MXN)</span>
                                <span>${(costo || 0).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')} MXN</span>
                            </div>
                            <button className="btn" onClick={()=>{
                                setReservarFixed(false);
                            }}>Aceptar</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="fakeReservar">
                <div className="resumen" onClick={()=>{
                        setReservarFixed(true)
                    }}>
                    <div className="total">
                        <span>${(costo || 0).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')} MXN</span>
                    </div>
                    <div>{espacios} espacio{espacios == 1 ? "" : "s"}</div>
                    {fechaI && fechaF &&
                        <div className="descAlmacen">{formatoFechaTxt(fechaI)} - {formatoFechaTxt(fechaF)}</div>
                    }
                </div>
                <div className="contReservar">
                    <button className="btn btnReservar" onClick={()=>{
                        if(user.sub){
                            if(fechaI && fechaF){
                                reservar();
                            }
                            else{
                                setReservarFixed(true)
                            }
                        }
                        else{
                            setModalLogin(true);
                        }
                    }}>
                        {cargando &&
                            <img src={Cargando}/>
                        }
                        {!cargando &&
                            <span>Reservar</span>
                        }
                    </button>
                </div>
            </div>
            <Outlet context={{publicacion}}/>
        </div>
    )
}  

const MapComponent = ({ position }) => {
    const position1 = {
        lat : Math.round(position.lat*100)/100,
        lng : Math.round(position.lng*100)/100,
    }
    return (
		<MapContainer center={position1} zoom={13} style={{ height: "100%", width: "100%" }}>
			<TileLayer
			url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
			attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
			/>
            <Circle center={position1} pathOptions={{fillColor: "blue"}} radius={4000} />
		</MapContainer>
    );
};

export default Almacen;