import React, { useEffect, useRef, useState } from "react";
import { baseUrl } from '../../config.js'
import { Link, useParams, useNavigate } from "react-router-dom";
import {Elements, PaymentElement, useStripe, useElements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import useToken from '../../useToken';

import { formatoFecha, formatoFechaTxt, formatoNumero, formatoNumeroEntero, imgEstatus, estatus } from "../../almacen.js";
import './Reservacion.css';
import Info from '../../img/Info.png';
import Cerrar from '../../img/Cerrar.png';
import descMedidas from '../../img/descMedidas.png';
import Copiar from '../../img/Copiar.png';

const stripePromise = loadStripe('pk_test_mvWe2DA5Ez2FX37mx2FIaMd2003sG0WdPw');

const Completado = ({})=>{

    return(
      <Elements stripe={stripePromise} options={{
          appearance: {/*...*/},
      }}>
          <PaymentStatus/>
      </Elements>
    )
}

const PaymentStatus = () => {
  const {token} = useToken();

  const { id } = useParams();
  const [reserva, setReserva] = useState({TypeTime:[]});
  const hoy = new Date();
  const [fechaI, setFechaI] = useState(new Date(hoy.getFullYear(), hoy.getMonth(), hoy.getDate()));
  const [fechaF, setFechaF] = useState();
  const [espacios, setEspacios] = useState(1);
  const [info, showInfo] = useState(false);
  const [cancelado, setCancelado] = useState(false);
  
  const stripe = useStripe();
  const [pago, setPago] = useState({});

  const divMedidas = useRef();

  useEffect(()=>{
      if(token)
          getData();
  },[token])

  const getData = async ()=>{
    const result1 = await fetch(baseUrl+"Booking/"+id, {
      headers:{
        'Authorization': `Bearer ${token}`,
      }
    })
    .then(response=>response.json())
    .then(json=>{
      console.log(json);
      return json;
    })
    .catch(error=>{
      console.error(error);
    })
    setReserva(result1?.data || {});
	setCancelado(result1?.data?.status == 'Canceled')
    setFechaI(new Date(result1?.data?.startDate.split("T")[0].split("-")));
    setFechaF(new Date(result1?.data?.endDate.split("T")[0].split("-")));
    setEspacios(result1?.data?.spaces);
    setPago(result1?.data?.paymentIntent);
  }
  const copiar = (valor)=>{
	navigator.clipboard.writeText(valor);
  }
  const cancelar = async ()=>{
    const result1 = await fetch(baseUrl+"Booking/"+id, {
		method: 'delete',
		headers:{
			'Authorization': `Bearer ${token}`,
		}
    })
    .then(response=>response.json())
    .then(json=>{
      console.log(json);
	  setCancelado(true);
      return json;
    })
    .catch(error=>{
      console.error(error);
    })
  }

  return(<>
  	<div className={"booking" + (!reserva?.idBooking ? " placeholder" : "")}>
	  	{cancelado &&
			<div className="cancelado">Está reservación fue cancelada</div>
		}
		<h1>Detalles de tu reservación</h1>
		<div className="divEstatus">
			<span className={reserva?.status}>
				{estatus[reserva?.status]}
				<img src={imgEstatus[reserva?.status]}/>
			</span>
		</div>
		<div className="gridBooking">
			<div className="datosReserva">
				{(reserva?.status == 'Pending' || reserva?.status == 'Partial') && reserva?.paymentIntent?.next_action &&
					<div className="etiqueta">
						<h4>Completa tu pago por:
							<h2>${formatoNumero(reserva.paymentIntent.next_action.display_bank_transfer_instructions.amount_remaining/100)} ({reserva.paymentIntent.next_action.display_bank_transfer_instructions.currency.toUpperCase()})</h2>
						</h4>
						<div className="completarPago">
							<div>
								<span>CLABE</span>
								<span>{reserva.paymentIntent.next_action.display_bank_transfer_instructions.financial_addresses[0].spei.clabe}</span>
								<span onClick={()=>copiar(reserva.paymentIntent.next_action.display_bank_transfer_instructions.financial_addresses[0].spei.clabe)}>
									<img src={Copiar}/>
								</span>
							</div>
							<div>
								<span>Beneficiario</span>
								<span>PalletCloud</span>
								<span onClick={()=>copiar('PalletCloud')}>
									<img src={Copiar}/>
								</span>
							</div>
							<div>
								<span>Referencia</span>
								<span>{reserva.paymentIntent.next_action.display_bank_transfer_instructions.reference}</span>
								<span onClick={()=>copiar(reserva.paymentIntent.next_action.display_bank_transfer_instructions.reference)}>
									<img src={Copiar}/>
								</span>
							</div>
							<div>
								<span>Nombre del Banco</span>
								<span>{reserva.paymentIntent.next_action.display_bank_transfer_instructions.financial_addresses[0].spei.bank_name}</span>
								<span onClick={()=>copiar(reserva.paymentIntent.next_action.display_bank_transfer_instructions.financial_addresses[0].spei.bank_name)}>
									<img src={Copiar}/>
								</span>
							</div>
						</div>
					</div>
				}
				<h2>Resumen de Renta</h2>
				<div className="resumenRenta">
					{reserva?.status == "Booked" &&
						<div>
							<span>

								<div><b>Ubicación</b></div>
								<div>
									{reserva?.street ? reserva.street+" " : ""}
									{reserva?.number ? reserva.number+", " : ""}
									{reserva?.zipCode ? reserva.zipCode+", " : ""}
									{reserva?.Town}, {reserva?.State}
								</div>
							</span>
						</div>
					}
					<div>
						<span>
							<div><b>Fechas</b></div>
							{fechaI && fechaF &&
								<div>Del {formatoFechaTxt(fechaI)} al {formatoFechaTxt(fechaF)}</div>
							}
						</span>
					</div>
					<div>
						<span>
							<div><b>Espacios</b></div>
							<div className="descAlmacen">{formatoNumeroEntero(reserva.spaces)} espacio{reserva.spaces == 1 ? "" : "s"}</div>
								<div className="descAlmacen medidas">
									<span>Ancho: {formatoNumero(reserva?.width/100)}m</span>
									<span>Largo: {formatoNumero(reserva?.length/100)}m</span>
									<span>Alto: {formatoNumero(reserva?.height/100)}m</span>
									<button className="info" onClick={()=>{
										showInfo(true)
									}}>
										<img src={Info}/>
									</button>
									{info &&
										<div className="descMedidas" ref={divMedidas}>
											<button className="cerrar" onClick={()=>{
												showInfo(false);
											}}>
												<img src={Cerrar}/>
											</button>
											<img src={descMedidas}/>
										</div>
									}
								</div>
						</span>
					</div>
				</div>
			</div>
			<div className="reservar">
				<div>
					<div className="resumenAlmacen">
						{reserva?.Photos && reserva?.Photos[0] &&
							<div className="miniAlmacen">
								<img src={reserva?.Photos[0].url}/>
							</div>
						}
						<div>
							<div>
								<div className="nombreAlmacen">{reserva?.name}</div>
								<div className="ubicacionAlmacen">{reserva?.Town+", "+ reserva?.State}</div>
							</div>
							<div className="puntua">
								<span className="puntuacionAlmacen">{reserva?.puntuacion || 4.93}</span>
								<span className="comentariosAlmacen">{reserva?.comentarios?.length || 15} comentarios</span>
							</div>
						</div>
					</div>
					<div className="resumenPrecio">
						<h2>Detalles del precio</h2>
						{fechaI &&
							<div className="intervalo">
								<div>
									<span>Del</span>
									<span>{formatoFechaTxt(fechaI)}</span>
								</div>
								{fechaF &&
								<>
									<div>
										<span>Hasta</span>
										<span>{formatoFechaTxt(fechaF)}</span>
									</div>
									<div className="subtotal first">
										<span>Subotal (MXN)</span>
										<span>${(reserva?.price*1 || 0).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')} MXN</span>
									</div>
									
									{reserva.method == 'card' &&
										<div>
											<span>Comisión (3.6%)</span>
											<span>${(reserva?.comision*1 || 0).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')} MXN</span>
										</div>
									}
									
									<div className="subtotal">
										<span>IVA (16%)</span>
										<span>${(reserva?.tax*1 || 0).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')} MXN</span>
									</div>
									<div className="total">
										<span>Total (MXN)</span>
										<span>${(reserva?.priceTotal*1).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')} MXN</span>
									</div>
								</>
								}
							</div>
						}
					</div>
					<div className="cancel">
						{!cancelado &&
							<button className="btn cancelar" onClick={()=>{
								if(window.confirm('¿Estás seguro que deseas cancelar esta reservación?')){
									cancelar()
								}
							}}>Cancelar Reservación</button>
						}
					</div>
				</div>
			</div>
		</div>
	</div>
  </>)
    
};


export default Completado;