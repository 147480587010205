import React, { useEffect, useRef, useState } from "react";
import { NavLink, useOutletContext } from "react-router-dom";
import {Elements, PaymentElement, useStripe, useElements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import { baseUrl, appUrl } from "../../config.js";

import { formatoMoneda, estatus, imgEstatus, formatoFecha } from "../../almacen.js";
import DashInouts from "../Dashboards/DashInouts.js";


import Modal from "../Modal/Modal";
import Cargando from '../../img/Cargando.gif';
import Tarjeta from '../../img/Tarjeta.png';
import Transferencia from '../../img/Transferencia.png';
import './Inout.css';
import useToken from "../../useToken.js";

const stripePromise = loadStripe('pk_test_mvWe2DA5Ez2FX37mx2FIaMd2003sG0WdPw');

const Inout = ()=>{

    const [reserva, setReserva] = useOutletContext();
    const [id, setId] = useState(false);

    console.log(reserva)

    return(
        <div className="inouts">
            <div className="solicita">
                <button className="btn" onClick={()=>{
                    setId(0);
                }}>Solicitar In/Out</button>
            </div>
            {id !== false &&
                <Modal titulo={'In/Out'} setModal={setId}>
                    <Info reserva={reserva} setReserva={setReserva} id={id}/>
                </Modal>
            }
            <DashInouts setId={setId}/>
        </div>
    )
}

const Info = ({reserva, id})=>{

    const {token} = useToken();

    const [info, setInfo] = useState({});
    const [price, setPrice] = useState({subtotal:0, total:0});
    const [cantidad, setCantidad] = useState({in: '', out:''});
    const [clientSecret, setClientSecret] = useState('');
    const [cargando, setCargando] = useState(false);
    const [metodo, setMetodo] = useState('Transferencia');
    const [error, setError] = useState("");
    const [pagar, setPagar] = useState(false);

    useEffect(()=>{
        if(id && token){
            getData();
        }
    }, [token])

    useEffect(()=>{
        const total = (cantidad?.in * reserva.priceIn) + (cantidad?.out * reserva.priceOut);
        const impuestos = 0.16;
        setPrice({subtotal: total, impuestos: total*impuestos, total:(total + total*impuestos)});
    }, [cantidad])

    const getData = async ()=>{
        const result1 = await fetch(baseUrl+"Inout/"+id, {
			headers:{
				'Authorization': `Bearer ${token}`,
			}
		})
		.then(response=>response.json())
		.then(json=>{
			console.log(json);
			return json;
		})
		.catch(error=>{
			console.error(error);
		})
		setInfo(result1?.data || {});
        setCantidad({
            in: result1?.data?.spacesIn || '',
            out: result1?.data?.spacesOut || '',
        })
    }

    const reservar = ()=>{
        setCargando(true);
        if(false){
            setError("Selecciona la cantidad de espacios");
            setCargando(false);
            return false;
        }
        const data = {
            idInout : id,
            idBooking : reserva?.idBooking,
            date: info?.date,
            time: info?.time,
            spacesIn : cantidad?.in,
            spacesOut : cantidad?.out,
        }
        fetch(baseUrl+"Inout", {
			method: "post",
			headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/json',
				'Authorization': `Bearer ${token}`,
			},
			body: JSON.stringify(data),
		})
		.then(response=>response.json())
		.then(json=>{
            setInfo({...info, status: 'Pending', idInout: json?.id})
		})
		.catch(error=>{
            setCargando(false);
			console.error(error);
		})
    }

    const pago = ()=>{
        fetch(baseUrl+"Inout", {
			method: "put",
			headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/json',
				'Authorization': `Bearer ${token}`,
			},
			body: JSON.stringify({method : metodo == 'Tarjeta' ? 'card' : 'customer_balance',  idInout: info?.idInout}),
		})
		.then(response=>response.json())
		.then(json=>{
            setCargando(false);
            setPagar(true);
            setClientSecret(json?.clientSecret);
		})
		.catch(error=>{
            setCargando(false);
			console.error(error);
		})
    }

    const defaultFecha = info?.date ? formatoFecha(new Date(info?.date?.split("T")[0].split("-"))) : "";

    return(
        <div className="infoInout">
            {info?.status &&
                <div className="divEstatus">
                    <span className={info?.status}>
                        {estatus[info?.status]}
                        <img src={imgEstatus[info?.status]}/>
                    </span>
                </div>
            }
            <form>
                <h3>Horario</h3>
                <div className="inputInout">
                    <label>
                        <input type="date" placeholder="" defaultValue={defaultFecha} min={formatoFecha(new Date())} onChange={(e)=>{
                            info.date = e.target.value;
                        }}/>
                        <span>Fecha</span>
                    </label>
                    <label>
                        <input type="time" placeholder="" defaultValue={info?.time} onChange={(e)=>{
                            info.time = e.target.value;
                        }}/>
                        <span>Hora</span>
                    </label>
                </div>
                <div>
                    <h3>Pallet Positions</h3>
                    <div className="inputInout">
                        <label>
                            <input type="number" placeholder="" value={cantidad.in} min={0} onChange={(e)=>{
                                cantidad.in = e.target.value;
                                setCantidad({...cantidad})
                            }}/>
                            <span>In (${formatoMoneda(reserva?.priceIn*1)} MXN)</span>
                        </label>
                        <label>
                            <input type="number" placeholder="" value={cantidad.out} min={0} onChange={(e)=>{
                                cantidad.out = e.target.value;
                                setCantidad({...cantidad})
                            }}/>
                            <span>Out (${formatoMoneda(reserva?.priceOut*1)} MXN)</span>
                        </label>
                    </div>
                </div>
                <div className="desglose">
                    <h3>Desglose</h3>
                    <div></div>
                    <div className="articulos">
                        {cantidad?.in != 0 &&
                            <i>
                                <span>{cantidad?.in} In x ${formatoMoneda(reserva?.priceIn*1 || 0)}</span>
                                <span>${formatoMoneda(cantidad?.in*reserva?.priceIn)}</span>
                            </i>
                        }
                        
                        {cantidad?.out != 0 &&
                            <i>
                                <span>{cantidad?.out} Out x ${formatoMoneda(reserva?.priceOut*1 || 0)}</span>
                                <span>${formatoMoneda(cantidad?.out*reserva?.priceOut)}</span>
                            </i>
                        }
                    </div>
                    <div>
                        <span>
                            <div>Subtotal</div>
                        </span>
                        <span>${formatoMoneda(price.subtotal)} MXN</span>
                    </div>
                    <div>
                        <span>Impuestos</span>
                        <span>${formatoMoneda(price.impuestos)} MXN</span>
                    </div>
                    <div>
                        <span>Total</span>
                        <span>${formatoMoneda(price.total)} MXN</span>
                    </div>
                </div>
            </form>
            {!info?.status &&
                <div>
                    <button type="button" className="btn black" onClick={()=>{
                        reservar();
                    }}>Solicitar In/Out</button>
                </div>
            }
            {info?.status == 'Approved' &&
                <div>
                    <h4>Selecciona la forma de Pago</h4>
                    <div className={'metodoPago'+( pagar ? ' selected' : '')}>
                        <button type="button" className={"btnPagar" + (metodo == 'Transferencia' ? ' sel' : '')} onClick={()=>{
                            setMetodo('Transferencia');
                        }}>
                            <span className="imgPagar">
                                <img src={Transferencia}/>
                            </span>
                            <span>Transferencia Bancaria</span>
                        </button>
                        <button type="button" className={"btnPagar" + (metodo == 'Tarjeta' ? ' sel' : '')} onClick={()=>{
                            setMetodo('Tarjeta');
                        }}>
                            <span className="imgPagar">
                                <img src={Tarjeta}/>
                            </span>
                            <span>Tarjeta de Crédito/Débito</span>
                        </button>
                    </div>
                    {metodo == 'Tarjeta' &&
                        <div>
                            <i>Este método de pago cobra un 3.6% por transacción</i>
                            <br></br>
                            <br></br>
                        </div>
                    }
                    <div>
                        {!pagar &&
                            <button type="button" className="btn btnReservar" onClick={()=>{
                                pago();
                            }}>
                                {cargando &&
                                    <img src={Cargando}/>
                                }
                                {!cargando &&
                                    <span>Continuar con el Pago</span>
                                }
                            </button>
                        }
                        {pagar && clientSecret &&
                            <Elements stripe={stripePromise} options={{
                                clientSecret: clientSecret,
                                appearance: {/*...*/},
                            }}>
                                <CheckoutForm id={info?.idInout} idBooking={id} />
                            </Elements>
                        }
                    </div>
                    <button type="button" className="btn cancelar">Cancelar In/Out</button>
                </div>
            }
        </div>
    )
}

const CheckoutForm = ({id, idBooking}) => {
    const stripe = useStripe();
    const elements = useElements();

    const [errorMessage, setErrorMessage] = useState(null);

    const handleSubmit = async (event) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();

        if (!stripe || !elements) {
        // Stripe.js hasn't yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
        }

        const {error} = await stripe.confirmPayment({
        //`Elements` instance that was used to create the Payment Element
        elements,
        confirmParams: {
            return_url: appUrl+'reservaciones/'+idBooking+'/inout',
        },
        });


        if (error) {
        // This point will only be reached if there is an immediate error when
        // confirming the payment. Show error to your customer (for example, payment
        // details incomplete)
        setErrorMessage(error.message);
        } else {
        // Your customer will be redirected to your `return_url`. For some payment
        // methods like iDEAL, your customer will be redirected to an intermediate
        // site first to authorize the payment, then redirected to the `return_url`.
        }
    };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <button className="btn continuar" disabled={!stripe}>Continuar</button>
      {/* Show error message to your customers */}
      {errorMessage && <div>{errorMessage}</div>}
    </form>
  )
};

export default Inout;