import React, { useRef } from "react";
import { baseUrl } from '../../config.js'
import { Link, useNavigate } from "react-router-dom";
import './Unirte.css';

import Contrato from '../../files/CONTRATO DE PRESTACIÓN DE SERVICIOS PALLETCLOUD - ANFITRIONES.pdf';

const Unirte = ({user, setModalLogin})=>{
    const navigate = useNavigate();

    const contrato = useRef();

    const aceptar = ()=>{
        if(contrato.current.reportValidity()){
            navigate('/host');
        }
    }

    return(
        <div className="unirte">
            <h1>¡Conviértete en Anfitrión!</h1>
            <div className="descUnirte">¿Tienes un almacén que no estás utilizando? ¡Haz que trabaje para ti! Únete a nuestra comunidad de anfitriones y comienza a ganar dinero alquilando tu espacio. Es fácil comenzar y te apoyamos en cada paso del camino. ¡Haz clic aquí para comenzar tu viaje como anfitrión hoy!</div>
            <div>
                {!user?.sub &&
                    <button className="btn" onClick={()=>{
                        setModalLogin(true)
                    }}>Quiero Unirme</button>
                }
                {user?.sub &&
                    <>
                        <div className="aceptarContrato">
                            <label>
                                <input type="checkbox" ref={contrato} required/>
                                <span>
                                    Acepto el 
                                    <a href={Contrato} target="_blank">Consentimiento para el Tratamiento de Datos Personales.</a>
                                </span>
                            </label>
                        </div>
                        <button onClick={aceptar} className="btn">Quiero Unirme</button>
                    </>
                }
            </div>
        </div>
    )
}

export default Unirte;